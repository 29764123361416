.simplepopover-to-edit {
    padding: 20px 20px;
    @include input-styles();
    border: 0;
    .detail-button, .remove-button {
        padding: 0px 5px;
        line-height: 1.1;
        a.link-details, .link-remove {
            font-weight: $font-weight-semi-bold;
            font-size: 12px;
            color: $textcolor;
            opacity: 0.8;
            cursor: pointer;
            @include transition();
            &:hover {
                opacity: 1;
                font-weight: $font-weight-bold;
                text-decoration: none;
            }
        }
    }
    .remove-button {
        padding-top: 5px;
        .link-remove{
            color: $redcolor;
        }
    }
}

.popover{
    background-color: aqua;
    border: 0;
}

.mySendButton {
    @include general-button();
}

.detail-left-grid {
    border-right: 1px solid $bordercolor;
    border-right-style: dotted;
    .grid-to-inputs {
        margin-right: 40px;
    }
    .general-width-to-inputs {
        width: 100%;
    }
    .hourly-rate-right {
        max-width: 80% !important;
    }
    .lstyletoinputs {
        input {
            @include input-styles();
            margin-top: 5px;
            height: 40px;
            padding-left: 30px;
        }
        label {
            @include input-label();
        }
    }
    .driver-color-picker {
        margin: 30px 0 18px 0;
        .color-picker-row {
            margin-top: 10px;
            display: flex;
            .muicc-colorpicker-button {
                margin: 1px 10px 5px 0px;
                height: 30px;
                box-shadow: none;
                border-radius: 5px;
                opacity: 1;
                &:hover, &:active, &:visited, &:focus {
                  opacity: 1;
                }
            }
        }
        input {
            text-align: center;
        }
    }
    .driver-name {
        font-size: 26px;
        font-weight: $font-weight-bold;
        margin-top: 20px;
        margin-bottom: 20px;
        .input-label {
            font-size: 12px;
            @include input-label();
            margin: 0;
            color: $textcolor;
        }
        .input-name {
            color: $textcolor;
        }
    }
    .grade-circle {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        display: inline-table;
        margin-right: 15px;
    }
    .hourly-rate-container {
        width: 100%;
        .input-hourly-rate {
            display: flex;
            width: 100%;
            gap: 1rem;
        }
        .input-hourly-rate div {
            margin-top: 0.1rem;
        }
        .input-divder {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .hourly-rate-divider {
            background-color: #707070;
            width: 0.4rem;
        }
    }
}

.labelCustom {
    font-size: 12px;
    @include input-label();
    margin: 0;
    color: $textcolor;
}

.simple-select-label {
    @include input-label(); 
}

.general-input-style {
    color: $textcolor;
}

#simple-select {
    @include input-styles();
    margin-top: 10px;
    padding: 18px 0px 11px 20px;
    width: 100%;
}

.date-select {
    @include input-styles();
    margin-top: 10px;
}

.add-orderdate-select {
    @include input-styles();
    margin-top: -25px;
}

.input-empty-space {
    margin: 30px 0;
}

.hourly-rate-field {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hourly-rate-input {
    margin: 0.4rem;
}

.hourly-rate-text-field {
    @include input-styles();
    width: 16rem;
}

.no-data-error-text, .saved-successfully-data-text {
    font-size: 12px;
}

.no-data-error-text {
    color: $declinebutton;
}

.saved-successfully-data-text {
    color: $acceptbutton;
}

.partner-saved-text {
    position: absolute;
    top: 78px;
    right: 6.5%;
}

.deleted-holiday-status {
    margin-bottom: 20px;
}
