.driver-operations {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .button {
        padding: 5px;
    }
}
