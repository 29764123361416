$white: #FFFFFF;
$background: #F5F7FA;
$textcolor: #707070;
$generalblue: #029EFF;
$black: #000000;
$green: #b8d900;
$qm-color: #333333;
$footerm: #585858;
$dogerblue: #3b9eff;
$redcolor: #E50000;
$acceptbutton: #1CB400;
$declinebutton: #D30000;
$menuhover: #2B2B2B;
$bordercolor: #C6C6C6;
$inactivegrey: #DBDBDB;
$textcolor-light: #7C7B7B;
$lineBorderColor: #DDDDDD;
$placeholderInput: #B8B8B8;
$royal-blue: #008ee7;
