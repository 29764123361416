.hourly-rate-container {
    width: 5%;
    margin-top: 3%;

    .table {
        display: table;
        width: 84%;
        height: 100%;
        border-collapse: collapse;
        table-layout: fixed;
    }

    .table-row {
        display: table-row;
    }

    .table-cell-vehicle,
    .table-head-vehicle,
    .table-cell-textfield-left,
    .table-cell-textfield-right,
    .table-cell-textfield-right-title,
    .table-cell-textfield-left-title,
    .table-cell,
    .table-head {
        display: table-cell;
        padding: 1em;
        text-align: center;
        vertical-align: middle;
        height: 2%;
    }

    .table-cell-vehicle,
    .table-head-vehicle,
    .table-cell-textfield-left,
    .table-cell-textfield-right,
    .table-cell-textfield-right-title,
    .table-cell-textfield-left-title,
    .table-head {
        height: 40px;
        font-weight: $font-weight-bold;
        color: $textcolor-light;
        font-size: 13px;
        text-transform: uppercase;
    }

    .checkbox-title {
        font-weight: $font-weight-bold;
        color: $textcolor-light;
        font-size: 13px;
        text-transform: uppercase;
    }
    
    .table-head-vehicle {
        text-align: left;
    }
    
    .table-cell-textfield-left {
        text-align: right;
    }

    .table-cell-textfield-right {
        text-align: left;
    }

    .table-cell-textfield-right-title {
        text-align: left;
        padding-left: 4%;
    }
    .table-cell-textfield-left-title {
        text-align: right;
        padding-right: 5%;
    }

    .table-cell-vehicle {
        text-align: left;
        font-size: 15px;
        vertical-align: middle;
    }
}
