.search {
    .search-input {
        background: url(../../Assets//search-icon.jpeg) no-repeat left #ffff;
        background-position-x: 10px !important;
        background-size: 20px;
        padding:0 30px 0 40px;
        width: 240px;
        height: 40px;
        margin-right: 60px !important;
        margin-top: 20px !important;
        outline: none;
        border: none;
        font-size: 14px;
        box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.04);
        color: #707070;
        cursor: pointer;
    }
}
 