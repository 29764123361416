.container-right-side {
    margin-left: 40px;

    .custom-margin-to-notes {
        margin-right: 3%;
    }

    .labelCustom {
        padding-bottom: 15px;
    }

    .driver-notes {
        @include input-styles();
        width: 100%;
        border: 0;
        padding: 20px;
    }

    .driver-notes-save-button {
        text-align: right;
        margin: 25px 0px 30px 0;
        .mySendButton {
            border-radius: 0;
            box-shadow: 0px 0px 6px 4px rgba(0,0,0,0.04);
            text-transform: uppercase;
            font-size: 12px;
            color: $white;
            font-weight: $font-weight-bold;
            border: 0;
            opacity: 0.9;
            @include transition();
            &:hover {
                opacity: 1;
                background-color: $generalblue;
            }
        }
    }
}

.history-notes-section {
    max-height: 500px;
    margin-bottom: 5%;
    overflow-y: auto;
    margin: 0px -35px;
    padding: 0px 35px;

    .driver-notes-list {
        width: 97%;
        .note-date {
            font-size: 12px;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            color: $textcolor;
            margin-bottom: 5px;
        }

        .note-content {
            @include input-styles();
            font-size: 14px;
            font-weight: $font-weight-regular;
            padding: 20px 0px 20px 20px;
            margin-bottom: 20px;
            z-index:0;
            position: relative;
            .history-title {
                font-weight: $font-weight-bold;
                text-transform: uppercase;
            }
            .note-list-grid {
                width: 98%;
            }
            &:hover {
                box-shadow: 0px 0px 8px 6px rgba(0, 0, 0, 0.07);
                @include transition();
                transform: scale(1.01);
            }
        }
    }

    .delete-history-note {
        text-align: right;
        margin-top: -30px;
        width: 2%;
        color: $textcolor;
        opacity: 0.6;
        position: absolute;
        right: 5px;
        top: 18px;
        cursor: pointer;
        font-size: 10px;
        &:hover {
            opacity: 1;
        }
    }
}

.driver-history-list {
    width: 85%;
    .labelCustom {
        margin: 40px 0 40px 0;
    }
}

.no-data-message {
    display: flex;
    color: $inactivegrey;
    align-items: center;
    font-size: 14px;
}
