.driver-timesheet-list {
    color: $textcolor;
    .driver-schedule-header {
        padding-left: 70px;
        .driver-info-section {
            display: flex;
            height: 50px;
            flex-direction: row;
            align-items: center;
            align-content: space-between;
            .driver-info {
                display: contents;
                .driver-color-row {
                    height: 46px;
                    width: 10px;
                }
                .driver-nr {
                    background-color: $white;
                    height: 46px;
                    width: 76px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: $font-weight-regular;
                    font-size: 16px;
                }
            }
            .driver-location {
                font-size: 16px;
                font-weight: $font-weight-bold;
                margin-left: 20px;
            }
        }
        .driver-name-row {
            margin-top: 20px;
            margin-bottom: 10px;
            .driver-name {
                font-size: 26px;
                font-weight: $font-weight-bold;
            }
        }
    }
    .week-row-to-schedule {
        margin-top: 50px;
        .timesheet-header-info {
            display: flex;
            flex-direction: row;
            width: 99%;
            text-transform: uppercase;
            align-items: flex-end;
            .empty-header-col {
                width: 265px;
            }
            .vehicle-type-col {
                width: 105px;
                text-align: center;
                font-weight: $font-weight-bold;
                font-size: 12px;
            }
            .hours-quantity-col {
                text-align: center;
                font-weight: $font-weight-bold;
                font-size: 10px;
                width: 55px;
            }
            .vehicle-type-col-ext {
                width: 100px;
                text-align: center;
                font-weight: $font-weight-bold;
                font-size: 12px;
            }
            .hours-quantity-col-ext {
                text-align: center;
                font-weight: $font-weight-bold;
                font-size: 10px;
                width: 52px;
               
            }
        }
        .driver-payment-details {
            .week-empty-col {
                width: 110px;
            }
            .empty-header-payment-col {
                width: 150px;
                padding-bottom: 6px;
            }
            .empty-header-col-details {
                width: 66%;
            }
            .empty-header-col,
            .empty-header-payment-col,
            .empty-header-col-details {
                font-size: 12px;
                font-weight: $font-weight-bold;
            }
            .hours-quantity-col {
                font-size: 12px;
                font-weight: $font-weight-semi-bold;
                height: 30px;
            }
            .driver-payment-details-total {
                text-align: right;
                font-size: 18px;
                font-weight: $font-weight-bold;
            }
        }
        .driver-timesheet-note {
            margin-top: 5px;
            margin-bottom: 10px;
            .week-empty-col {
                width: 145px;
            }
            .empty-header-col-details,
            .driver-payment-details-total {
                width: 50%;
                font-size: 12px;
                font-weight: $font-weight-bold;
                padding-bottom: 10px;
            }
            .driver-payment-details-total {
                text-align: right;
            }
            .timesheet-note {
                @include input-styles();
                width: 100%;
                padding: 8px 10px;
                border: 0;
                height: 50px;
            }
        }
        .company-payment-list {
            margin-bottom: 20px;
        }
        .driver-payment-details-line {
            border-bottom: 1px solid $lineBorderColor;
            margin-bottom: 10px;
        }
        .week-nr-col {
            float: left;
            .note-date {
                font-size: 21px;
                font-weight: $font-weight-bold;
            }
        }
        .driver-week-list {
            margin-left: 65px;
            .week-col {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 5px;
                font-size: 13px;
                font-weight: $font-weight-bold;
                .timesheet-location {
                    width: 150px;
                    background-color: $white;
                    padding: 8px 10px;
                    text-align: left;
                }
                .timesheet-hours {
                    width: 55px;
                    margin-left: 5px;
                    font-weight: 700;
                    input {
                        text-align: center;
                        font-size: 13px;
                        font-weight: $font-weight-bold;
                        height: 35px;
                        padding: 0;
                        &:hover,
                        &:visited,
                        &:focus,
                        &:active {
                            background-color: #C4FCFC;
                        }
                    }
                    .modified-driver-job-input {
                        input {
                            background-color: #C4FCFC;
                        }
                    }
                }
                .day-name {
                    font-size: 14px;
                    font-weight: $font-weight-bold;
                    width: 40px;
                }
                .select-warehouse {
                    width: 280px;
                    .general-width-to-inputs {
                        width: 98%;
                        .simple-select-driver-schedule {
                            @include input-styles();
                            padding: 7px 6px 0px 10px;
                            margin: 5px;
                        }
                    }
                }
            }
            .circle-list {
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: $textcolor;
                margin: 1px 0;
                display: block;
            }
        }
    }
}
.timesheet-mini-input {
    font-weight: bold;
    font-size: 13px;
}

.search-error-msg {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}
