html, body, h1, h2 {
    margin: 0;
    padding: 0;
    font-family: $font-family;
    color: $textcolor;
}

a {
    text-decoration: none;
    color: $textcolor;
    &:hover {
        font-weight: $font-weight-semi-bold;
        text-decoration: none;
    }
}

* {
    margin: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.clr {
    clear: both;
}
