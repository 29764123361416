.title-container {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.partner-buttons {
    background-color: $generalblue;
    color: $white;
    height: 40px;
    padding: 0px 15px;
    margin-left: 5px;
    border: none;
    align-items: center;
    font-size: 15px;
}

.partner-buttons:hover {
    background-color: $royal-blue;
}

.partner-buttons:disabled {
    background-color: $placeholderInput;
}