.default-table-container {
    width: 95%;
    .default-table-list {
        max-height: 400px;
        .colorCode-cell {
            min-width: 30px;
            text-align: left;
        }
        .nr-cell {
            min-width: 100px;
            text-align: left;
        }
        .nr-cell-inactive {
            min-width: 130px;
            text-align: left;
        }
        .driver-name-cell {
            min-width: 100px;
            text-align: left;
        }
        .location-cell {
            min-width: 100px;
            text-align: left;
        }
        .id-inactive-cell {
            min-width: 250px;
            text-align: right;
        }
        .edit-cell {
            min-width: 30px;
            text-align: right;
        }
        .start-date-cell {
            min-width: 15px;
            text-align: right;
        }
        .spliter-cell {
            text-align: center;
            padding-left: 5px;
            padding-right: 5px;
        }
        .end-date-cell {
            min-width: 15px;
            text-align: left;
        }
        .start-date-cell-old {
            min-width: 15px;
            text-align: right;
        }
        .spliter-cell-old {
            text-align: center;
            padding-left: 5px;
            padding-right: 5px;
        }
        .end-date-cell-old {
            min-width: 15px;
            text-align: left;
        }
        .location-cell-old {
            text-align: left;
            max-width: 110px;
        }
        .driver-name-cell-old {
            text-align: left;
            max-width: 160px;
        }
        .nr-cell-old {
            text-align: left;
            padding-left: 15px;
            min-width: 77px;
        }
        .table-list-head {
            background-color: $background;
            text-transform: uppercase;
            .table-list-head-cell {
                background-color: $background;
                color: $textcolor;
                border: 0;
                font-size: 12px;
                font-weight: $font-weight-bold;
                box-shadow: 0 2px 0px rgba(0, 0, 0, 0.02);
            }
        }
    }
    .default-table-body {
        .table-fullwidth-row {
            @include table-tr-style();
            @include transition();
            .columns-grid {
                color: $textcolor;
                border: 6px;
                font-size: 16px;
                padding-top: 8px;
                .grade-circle-cell {
                    .grade-circle {
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        display: inline-table;
                    }
                }
            }
            .driver-name {
                font-weight: $font-weight-bold;
            }
            .edit-drivers {
                font-size: 20px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
                .circle-list {
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background-color: $textcolor;
                    margin: 1px 0;
                    display: block;
                }
                .remove-button {
                    background-color: white;
                    color: $declinebutton;
                    box-shadow: none;
                }
            }
        }
    }
    .table-pagination {
        @include table-pagination();
    }
}
.driver-inactive-registration-list {
    margin-top: 3%;
}

.no-data-error-text {
    color: $declinebutton;
    font-size: 16px;
}
