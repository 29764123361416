.login-screen {
  background-color: whitesmoke;
  background-position: center;
  background-size: cover;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;

.login-form-box {
    align-items: center;
    background-color: white;
    min-width: 350px;
    padding: 2rem;
    margin-top: 11%;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    & .email {
      color:dodgerblue;
    }
    & .password {
      color:dodgerblue;
      margin-bottom: 20px;
    }
    & .title {
      font-weight: bold;
      padding-bottom: 2rem;
      color: dimgray;
    }
    & .button {
      background-color: dodgerblue;
      margin-top: 2rem;
      height: 50px;
      font-weight: bold;
      color: white;
    }
  }

  .title-container {
    margin-top: 1%;
    align-items: center;
    display: flex;
    flex-direction: column;
    .bigTitle {
      color: dimgray;
      font-weight: bold;
    }
  }
}
