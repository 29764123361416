.invoice-grid-style {
    margin-top: 3%;
    .week-nr-col {
        @include week-nr();
        margin-top: 40%;
    }
    .invoice-header-section, .invoice-content-section {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        font-size: 12px;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        width: fit-content;
        .total-amount-col {
            width: 180px;
            padding-left: 20px;
        }
        .tax-zone-point-col {
            width: 180px;
            padding-left: 13px;
        }
        .warehouse-col {
            width: 200px;
            padding-left: 20px;
        }
        .van-col, .hiab-col {
            text-align: center;
            font-size: 16px;
            width: 250px;
            .multiple-label-in-header {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                font-size: 12px;
                .hours-overtime-col {
                    text-align: right;
                    width: 50%;
                    padding-right: 35px;
                }
            }
        }
        .order-nr-col {
            width: 200px;
            label {
                margin-bottom: -1px;
            }
        }
        .tax-zone-col {
            width: 200px;
            display: flex;
                justify-content: flex-start;
                align-items: center;
            label {
                margin-bottom: -1px;
            }
        }
        .invoice-nr-col, .status-col {
            width: 150px;
            label {
                margin-bottom: 0;
                width: 90%;
            }
        }
    }
    .invoice-content-section {
        font-size: 14px;
        text-transform: unset;
        align-items: center;
        .warehouse-col {
            font-weight: $font-weight-bold;
            padding-left: 0;
        }
        .van-col, .hiab-col {
            font-weight: $font-weight-regular;
            .multiple-label-in-header {
                font-size: 14px;
                .hours-col {
                    text-align: left;
                    width: 50%;
                    margin-left: 30px;
                }
                .hours-overtime-col {
                    text-align: left;
                    width: 50%;
                    margin-left: 70px;
                }
            }
        }
        .tax-zone-point-col {
            font-weight: $font-weight-bold;
            padding-left: 30px;
        }
        .total-amount-col {
            font-weight: $font-weight-bold;
            padding-left: 0px;
        }
        .invoice-nr-col {
            padding-right: 40px;
        }
        .dot {
            height: 10px;
            width: 10px;
            background-color: #FF8A00;
            border-radius: 50%;
            display: inline-block;
        }
        .dot-inactive {
            height: 10px;
            width: 10px;
            background-color: #B8B8B8;
            border-radius: 50%;
            display: inline-block;
        }
    }
    .invoice-divider {
        margin: 0px 20px 5px 15px;
    }
    .invoice-details {
        .invoice-description-label {
            font-size: 12px;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
        }
        .date-range {
            font-size: 16px;
            font-weight: $font-weight-regular;
        }
        .invoice-day-details {
            .invoice-content-section {
                .warehouse-col {
                    font-size: 16px;
                    font-weight: $font-weight-regular;
                }
            }
        }
    }
    .no-invoice-added {
        margin-top: 55px;
    }
}

.invoice-status-select {
    width: 100%;
}

.invoice-select-week {
    padding: 5px 8px;
}

.week-select-section {
    position: absolute;
    right: 80px;
    top: 30px;
    .select-week-input {
        @include input-styles();
        padding: 10px 15px;
        cursor: pointer;
        &:hover {
            box-shadow: 0px 0px 6px 4px rgba(0,0,0,0.06);
        }
    }
}
