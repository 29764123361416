@mixin transition() {
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

@mixin primary-title() {
    font-weight: $font-weight-bold;
    color: $textcolor; 
    font-size: 16px;
    text-transform: uppercase;
}

@mixin secondary-title() {
    font-size: 11px;
    letter-spacing: 3px;
    font-weight: $font-weight-semi-bold;
    color: $textcolor; 
    text-transform: uppercase;
}

@mixin input-label {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
}

@mixin input-styles {
    font-size: 14px;
    box-shadow: 0px 0px 6px 4px rgba(0,0,0,0.04);
    background-color: $white;
    border-bottom: 0;
    color: $textcolor;
}

@mixin general-button {
    background-color: $generalblue;
    border-radius: 0;
    box-shadow: 0px 0px 6px 4px rgba(0,0,0,0.04);
    text-transform: uppercase;
    font-size: 12px;
    color: $white;
    font-weight: $font-weight-bold;
    border: 0;
    opacity: 0.9;
    @include transition();
    &:hover {
        opacity: 1;
        background-color: $generalblue;
    }
}

@mixin table-tr-style {
    box-shadow: inset 0px 5px 0px rgba(0, 0, 0, 0.02);
    -moz-box-shadow: inset 0px 5px 0px rgba(0, 0, 0, 0.02);
    -webkit-box-shadow:  inset 0px 5px 0px rgba(0, 0, 0, 0.02);
}

@mixin week-nr {
    font-size: 21px;
    font-weight: $font-weight-bold;
}

@mixin table-pagination {
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 6px 16px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0 6px 16px rgba(0, 0, 0, 0.16);
    color: $textcolor;
    font-size: 12px;
    font-weight: $font-weight-bold;
    margin-top: 5px;
    background-color: $white;
}
