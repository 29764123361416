.default-table-container {
  width: 95%;
  .default-table-list-partner {
      max-height: 95%;
      .nr-cell {
          min-width: 40px;
          padding-left: 10px;
          text-align: left;
      }
      .partner-name-cell {
          min-width: 150px;
          padding-left: 0px;
          text-align: left;
      }
      .location-cell {
          min-width: 150px;
          padding-left: 30px;
          text-align: left;
      }
      .partner-since-cell {
          padding-left: 20px;
          margin-right: 50px;
          min-width: 170px;
          text-align: left;
      }
      .divider-cell {
        min-width: 30px;
      }
      .rate-cell {
        min-width: 110px;
        padding-left: 10px;
    }
      .table-list-head {
          background-color: $background;
          text-transform: uppercase;
          .table-list-head-cell{
              background-color: $background;
              color: $textcolor;
              border: 0;
              font-size: 12px;
              font-weight: $font-weight-bold;
          }
      }
  }
  .default-table-body {
      .table-fullwidth-row {
          height: 60px;
          @include table-tr-style();
          @include transition();
      }
  }
}
