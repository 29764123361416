.btn-edit {
    background-color: transparent;
}

@import "~bootstrap/scss/bootstrap";

.popover {
    border: 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.04);
}

.nav-link {
    display: block;
    padding: 0.5rem 0rem;
}

#week-select-popover .DayPicker-Day {
    border-radius: 0;
}
