.partner-popup {
    .button {
        background-color: $generalblue;
        border: 0;
        height: 40px;
        position: absolute;
        top: 30px;
        right: 6.5%;
    }
}

.main-container {
    width: 100%;
    margin: 5% 3% 3% 3%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .title-container {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        width: 93%;

        .xmark {
            size: 25px;
        }

        .title {
            font-size: 16px;
            font-weight: $font-weight-bold;
            color: $textcolor;
            text-transform: uppercase;
        }

        .sub-title {
            font-size: 11px;
            letter-spacing: 3px;
            font-weight: $font-weight-bold;
            color: $textcolor;
            text-transform: uppercase;
        }
    }

    .text-field-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 95%;

        .text-field-title {
            margin-top: 13px;
            font-weight: $font-weight-bold;
            color: $textcolor-light;
            font-size: 13px;
            text-transform: uppercase;
        }

        .add-partner-textfield-container {
            width: 100%;
            flex-grow: 1;
            margin: 5px;
        }
    }

    .text-field-container-emails {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 95%;

        .text-field-title {
            margin-top: 13px;
            font-weight: $font-weight-bold;
            color: $textcolor-light;
            font-size: 13px;
            text-transform: uppercase;
        }

        .text-field-title-placeholder {
            margin-top: 30px;
        }

        .add-email-textfield-container {
            width: 32.2%;
            margin: 5px;
        }
    }

    .hourly-rate-container {
        display: flex;
        width: 95%;
        margin-top: 3%;

        .vl {
            border-left: 1px solid $placeholderInput;
            height: 380px;
            margin-left: 20px;
            margin-right: 20px;
        }

        .table {
            display: table;
            width: 100%;
            height: 100%;
            border-collapse: collapse;
            table-layout: fixed;
        }

        .table-row {
            display: table-row;
        }

        .table-cell-vehicle,
        .table-head-vehicle,
        .table-cell,
        .table-head {
            display: table-cell;
            padding: 1em;
            text-align: center;
            vertical-align: middle;
            height: 2%;
        }

        .table-cell-vehicle,
        .table-head-vehicle,
        .table-head {
            height: 40px;
            font-weight: $font-weight-bold;
            color: $textcolor-light;
            font-size: 13px;
            text-transform: uppercase;
        }

        .table-head-vehicle {
            text-align: left;
        }

        .table-cell-vehicle {
            text-align: left;
            font-size: 15px;
        }
    }

    .button-container {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        width: 95%;
        margin-top: 2%;

        .save-button {
            background-color: $generalblue;
            color: $white;
            height: 40px;
            padding: 0px 15px;
            border: none;
            align-items: center;
            font-size: 15px;
        }

        .save-button:hover {
            background-color: $royal-blue;
        }
    }
}

#date-picker-inline-start,
#date-picker-inline-end {
    padding-left: 15px;
    padding-bottom: 15px;
    border: 0;
}