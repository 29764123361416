.order-popup {
  .button {
    background-color: $generalblue;
    border: 0;
    height : 40px;
    position: absolute;
    top: 30px;
    right: 6.5%;
  }
}

.container {
  width: 100%; 
  justify-content: center;
  align-items: center;
}

.inner-container {
  width: 90%;
  margin-left: 5%;
  .title {
    margin-top: 40px;
    font-size: 16px;
    font-weight: $font-weight-bold;
    color: $textcolor;
    text-transform: uppercase;
  }
  .sub-title {
    margin-bottom: 50px;
    font-size: 11px;
    letter-spacing: 3px;
    font-weight: $font-weight-bold;
    color: $textcolor;
    text-transform: uppercase;
  }
  .text-field-title {
    margin-top: 13px;
    font-weight: $font-weight-bold;
    color: $textcolor-light;
    font-size: 13px;
    text-transform: uppercase;
  }
}

#date-picker-inline-start, #date-picker-inline-end{
  padding-left: 15px;
  padding-bottom: 15px;
  border: 0;
}
