.job-tracking-screen {
    .job-left-side {
        border-right: 1px solid $bordercolor;
        border-right-style: dotted;
        .driver-nr {
            display: flex;
            height: 50px;
            align-items: center;
            width: 100%;
            .driver-info {
                display: contents;
                .driver-color-row {
                    height: 50px;
                    width: 10px;
                    box-shadow: 0px 0px 6px 4px rgba(0,0,0,0.04);
                }
                .driver-nr {
                    background-color: $white;
                    height: 50px;
                    text-align: left;
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    font-weight: $font-weight-bold;
                    font-size: 16px;
                    box-shadow: 0px 0px 6px 4px rgba(0,0,0,0.04);
                    padding: 0 20px;
                    width: fit-content;
                }
            }
        }
        .driver-name {
            font-weight: $font-weight-bold;
            font-size: 26px;
        }
    }
    .notification-list {
        margin-top: 80px;
        .boxes-notification {
            margin-top: 20px;
        }
    }
    .white-box {
        margin-top: 20px;
        .input-name {
            @include input-styles();
            padding: 12px 15px;
            font-size: 16px;
            min-height: 45px;
        }
        .input-name.style-bold {
            font-weight: $font-weight-bold;
        }
    }
    .container-right-side {
        width: 70%;
        .manager-signature {
            margin-top: 20px;
            .signature-image {
                @include input-styles();
                padding: 50px;
                text-align: center;
                min-height: 100px;
                .sign-manager-image {
                    width: 100%;
                }
            }
        }
        .driver-map {
            margin-bottom: 50px;
            .map-image {
                .map-position {
                    height: 400px;
                }
            }
        }
        .leaflet-bottom {
            display: none;
        }
    }
}

.map-icon-style {
    width: 16px;
    height: 16px;
    border: 0;
}

@media only screen and (max-width: 1350px) {
    .job-tracking-screen {
        .job-left-side {
            .driver-nr {
                .driver-info {
                    .driver-nr {
                        font-size: 20px;
                    }
                } 
            } 
        }
    }
}

.back-button {
    color: $textcolor;
    font-size: 25px;
    margin-right: 20px;
    text-align: center;
}

.back-button-title {
    display: flex;
    align-items: center;
}
