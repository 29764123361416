.navigationmenu {
    background-color: $white;
    min-height: 100vh;
    padding: 0;
    width: 12%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
    z-index: 6;
    .remove-button {
        background-color: white;
        color: $declinebutton;
        box-shadow: none;
        text-transform: uppercase;
        margin-left: -7px;
        font-size: 14px;
        @include transition();
        font-weight: $font-weight-bold;
        &:hover {
            color: $menuhover;
            background-color: white;
           }
    }
   .primary-left-menulist {
        padding-left: 30px;
        margin-top: 20%;
        .navigation-list {
            padding: 0;
            margin: 0;
            .nav-link {
                list-style-type:none;
                margin: 0;
            }
        }
        a.link-name {
           font-weight: $font-weight-bold;
           color: $textcolor; 
           font-size: 14px;
           @include transition();
           text-transform: uppercase;
           &:hover {
            color: $menuhover;
           }
        }
    }
    .dashboard-title {
        padding: 30px;
        .primary-title {
           @include primary-title();
        }
        .secondary-title {
            @include secondary-title();
        }
    }
}
